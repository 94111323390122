/* eslint-disable import/no-anonymous-default-export */
import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
const Deposit = lazy(() => import('./pages/deposit'))
const Growth = lazy(() => import('./pages/growthgrowthgrowth'))
const Details = lazy(() => import('./pages/growthgrowthgrowth/details'))
const jaJP = lazy(() => import('./pages/jaJP'))
export default () => {
  return (
    <BrowserRouter>
      <Switch>
      <Suspense fallback={< ></> }>
        {/* <Route exact path="/" component={Index}/>
        <Route  path="/deposit" component={Deposit} /> */}
        <Route exact path="/" component={Growth} />
        <Route  path="/details" component={Details} />
        
        <Route  path="/jaJP" component={jaJP} />
        
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};
